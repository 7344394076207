// Variable overrides


.error-msg {
    font-size: 12px;
    margin: 0px;
    top: -10px;
}

.sc-hKwCoD.sc-eCImvq.sc-iqsfdx.zmfZH.bMYmCz.dnoXhz.rdt_TableCol {
    color: black;
    font-weight: 900;
}

button.download-processed-file-btn {
    min-width: max-content;
}

.table_wrapper{
    display: block;
    overflow-x: auto;
    white-space: nowrap;
    margin: 1rem 0
}