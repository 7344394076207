// Here you can add other styles

$primary-color: #383BE8;
$border-color: #eee;
$bg-color: #f9f9f9;
$error-color: red;

.card-style {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
  width: 18rem;
}

.file-input-container {
  display: flex;
  align-items: flex-start;
}

.display-files-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #d9dbe0;
  margin-bottom: 2px;
  border-radius: 5px;
}

.display-files {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: center;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
}

.spinner-style {
    width: 50px;
    height: 50px;
}

.form__group_option {
    width: 100%;
}

.select_listmatch_id {
    display: flex;
    margin-bottom: 2.5vh;
}

.dropdown_option {
  position: relative;

  &__selected {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 20px 0 10px;
    font-size: .875rem;
    border: 2px solid $border-color;
    border-radius: 0 5px 5px 0;
    position: relative;
    cursor: pointer;
    transition: box-shadow .3s ease;
    color: #768192;
    overflow: auto;

    &::after {
      top: calc(50% - 2px);
      right: 10px;
      border: solid transparent;
      content: '';
      height: 0;
      width: 0;
      position: absolute;
      border-top-color: #000;
      border-width: 4px;
      margin-left: -4px;
    }

    &:hover {
        color: #768192;
        background-color: #fff;
        border-color: #958bef;
        outline: 0;
        box-shadow: 0 0 0 0.2rem rgba(50,31, 219, 0.25);
    }
  }

  &__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    border: 1px solid $border-color;
    border-top: 0;
    background-color: #fff;
    z-index: 5;

    &_items {
      max-height: 210px;
      overflow-y: auto;
    }

    &_search {
      display: block;
      width: 100%;
      border: 0;
      border-bottom: 1px solid $border-color;
      padding: 12px;
      outline: 0;
      background-color: $bg-color;
    }

    &_item {
      padding: 10px;
      border-bottom: 1px solid $border-color;
      font-size: 14px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }

      &:hover {
        background-color: $border-color;
      }

      &.selected,
      &.selected:hover {
        background-color: $primary-color;
        color: #fff;
      }
    }
  }
}

.process-success{
  color: white;
  font-weight: 600;
  background: #138513;
  padding: 4px 7px;
  display: flex;
  justify-content: center;
  cursor:pointer;
}

.process-pending{
  @extend .process-success;
  background: #CB0E0E;
}

.lr_process_style{
  min-width: 7rem;
}

tfoot{
  display: none;
}

.export-btn-wrap {
  position: relative;
  float: right;
  top: -60px;
  right: 120px;
}

.download-list-data-btn {
  position: absolute;
  width: 120px;
  border-radius: 10px;
  background-color: #3885e8;
  color: white;
  font-size: 18px;
  font-weight: 900;
  padding: 6px;
}

.popup-wrap {
  background-color: #3c4b64;
  width: 600px;
  height: 400px;
  color: white;
  text-align: left;
  padding: 20px;
  font-size: 16px;
  overflow: auto;
  font-weight: 600;
  line-height: 1.5;
  white-space: wrap;
}

.pop-up-button {
  white-space: nowrap;
  padding: 5px 10px;
  background-color: grey;
  color: white;
  font-weight: 700;
  font-size: 14px;
  border-radius: 10px;
}

.file-popup-title {
  font-weight: 700;
  line-height: 2;
  text-align: center;
  border-bottom: 2px double #000;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.popup-close {
  position: relative;
  float: right;
  bottom: 410px;
  border-radius: 20px;
  font-size: 16px;
  font-weight: 900;
  background-color: white;
  color: red;
  right: -10px;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #7983ff;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
